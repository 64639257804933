.navigation-button {
    margin-right: 1rem !important;
}

.navigation-page-number {
    padding-bottom: 1rem;
}

.collection-pagination {
    border-top: 0.1875rem solid #1a1a1a;
    border-bottom: 0.1875rem solid #1a1a1a;
    background-color: #0077a056;
    padding: 1rem;
}
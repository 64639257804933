.nav-container {
    background-color: #404040;
    color: #fff;
    font-size: 1.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;

    & .nav-row {
        margin: 0 auto;
        max-width: 72rem;
        justify-content: flex-end;

        & a.nav-link:hover {
            text-decoration: underline;
        }
    }

}

.nav-link-custom {
    color: #fff !important;
    padding-left: 0rem !important;
    padding-right: 2rem !important;
}

.nav-link {
    color: #fff;
    text-decoration: none;
}

.nav-username {
    padding-right: 2rem;
    color: #fff;
}

.notification-badge {
    margin-right: 2rem;
}

.username-span {
    margin-left: 2rem;
}
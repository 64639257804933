
.spacer-row {
    height: 2rem;
}

.custom-input-label {
    font-weight: bold;
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
    cursor: pointer;
}

.custom-input-label__flag {
    margin-left: 0.5rem;
}
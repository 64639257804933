
// @import "../../node_modules/bootstrap/scss/functions";

// $navbar-dark-toggler-border-color: #fff;
// $navbar-light-toggler-border-color: #fff;
// $navbar-toggler-border-color: #fff;

// @import "../node_modules/bootstrap/scss/variables";
// @import "../node_modules/bootstrap/scss/mixins";
// @import "../node_modules/bootstrap/scss/nav";

$navbar-dark-toggler-border-color: #fff;
$navbar-dark-color: #fff;

$font-family: "Roboto","Helvetica","Arial",sans-serif;

@import "../../node_modules/bootstrap/scss/bootstrap";

// .page-header-row {
//     padding-top: 2rem;
//     padding-bottom: 2rem;
// }

// body {
//     margin: 0;
//     font-family: $font-family;
// }

// h1 {
//     font-family: $font-family;
// }

// b, strong {
//     font-weight: bolder;
// }

.avatar-image {
    border-radius: 20px;
}
.collection-details-row {
    //margin-bottom: 0.25rem;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    //margin-top: 0.25rem;
    //border-bottom: 1px solid #1a1a1a;
    --bs-gutter-x: 0rem !important;
}

.album-image {
    max-width: 75px;
    height: auto;
    width: auto/9;
    padding-left: 0.5rem;
}

.user-collection .collection-row:nth-child(2n+1) {
    background-color: #c0c0c0;
}

.collection-listen-button-column {
    text-align: right;
}

.collection-listen-button {
    margin-right: 0.5rem !important;
}

.sort-label {
    font-size: 1.5rem;
}

.collection-search-button {
    margin-right: 2rem;
}

.listen-button-column {
    text-align: right;
}

.listen-button {
    padding-right: 0.5rem;
}
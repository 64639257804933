.custom-rating {

    & .MuiRating-iconFilled {
        color: #278421;
    }

    & .MuiRating-iconHover {
        color: #278421;
    }
    
}
.main-content-container {
    background-color: #fff;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    min-height: 50vw;
    display: flex;
    flex-direction: column;

    & .main-content-row {
        margin: 0 auto;
        max-width: 72rem;
        justify-content: flex-end;
        
    }

}

.global-linear-progress {
    height: 4px;
}
.images-loading {
    width: 75px;
    height: 4px;
}

.images-container {
    width: 500px;
    height: 500px;
    text-align: center;
    background-color: #ffffff;
    text-align: center;
}

.images-container img {
    max-height: 100%;
    max-width: 100%;
}

.vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

.images-close-button {
    margin-left: auto;
}
.timeline-image {
    max-width: 25px;
    height: auto;
    width: auto/9;
}

.title-link {
    // color: #1a1a1a;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.notes-container {
    padding-top: 1rem;
}
.footer-content-container {
    background-color: #1a1a1a;
    color: #fff;
    font-size: 0.85rem;

    & .footer-content-row {
        margin: 0 auto;
        max-width: 72rem;
        justify-content: flex-end;
        
    }

    & a:link {
        color: #fff;

        &:hover {
            text-decoration: none;
        }

    }

    & a:visited {
        color: #fff;
    }

}

.footer-container-image {
    padding-top: 4rem;
    padding-bottom: 4rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.footer-menu-container {
    background-color: #1a1a1a;
    opacity: 0.9;
    padding: 2rem;
    border-radius: 0.25rem;
}